import { cn } from "@/utils/helpers";
import React, { SVGAttributes } from "react";

const FtseBuilding = ({
  width = 32,
  height = 33,
  className = "flex-shrink-0",
  ...props
}: {
  width?: number;
  height?: number;
  className?: string;
  props?: SVGAttributes<SVGElement>;
}) => {
  return (
    <svg
      width="32"
      height="33"
      className={cn(className)}
      {...props}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 19.5021V23.5021M16 19.5021V23.5021M21.3333 19.5021V23.5021M4 28.8354H28M4 14.1688H28M4 10.1688L16 4.83545L28 10.1688M5.33333 14.1688H26.6667V28.8354H5.33333V14.1688Z"
        stroke="#101626"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FtseBuilding;
